import Banner from "../components/common/banner/Banner";
import HomeAbout from "../components/home/homeAbout/HomeAbout";
import "../components/home/homeAbout/HomeAbout.scss";
import HomeProduct from "../components/home/homeProduct/HomeProduct";
import "../components/home/homeProduct/HomeProduct.scss";
import "../components/common/card/Card.scss"
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Fade from 'react-reveal/Fade';

export default function Services() {
  const {t} = useTranslation()
  const photos = [
    {
      id: "b1",
      url: "Shades.jpg",
      headlineText: t("banner.3"),
    },
    {
      id: "b2",
      url: "ServiceBanner3.jpg",
      headlineText: t("banner.3"),
    },
    {
      id: "b3",
      url: "ServiceBanner4.jpg",
      headlineText: t("banner.3"),
    },
  ];
  const row1 = [
    {
      title1: t('home:homeProduct.card1.title'),
      text2: t('home:homeProduct.card1.text'),
      url: "Prod1.png",
      link: "/Services",
    },
    {
      title1: t('home:homeProduct.card2.title'),
      text2: t('home:homeProduct.card2.text'),
      url: "/Tire.jpg",
      link: "/Services",
    },
    {
      title1: t('home:homeProduct.card3.title'),
      text2: t('home:homeProduct.card3.text'),
      url: "Prod4.png",
      link: "/Services",
    },
  ];
  const row2 = [
    {
      title1: t('home:homeProduct.card4.title'),
      text2: t('home:homeProduct.card4.text'),
      url: "powerDis2.png",
      link: "/Services",
    },
    {
      title1: t('home:homeProduct.card5.title'),
      text2: t('home:homeProduct.card5.text'),
      url: "Rubber.jpg",
      link: "/Services",
    },
    {
      title1: t('home:homeProduct.card6.title'),
      text2: t('home:homeProduct.card6.text'),
      url: "Paper2.jpg",
      link: "/Services",
    },
  ];

  
  return (
    <div>
    <Helmet>
    <title>{t('title.3')}</title>
</Helmet>
      <Banner
        photos={photos}
      />
      <div className="service-about">
        <Fade>
          <HomeAbout
            titleIntro={t('service:service1.title1')}
            title={t('service:service1.title2')}
            text={t('service:service1.text')}
            url="service-img.png"
          />
        </Fade>
        <div className="bottom">
          <HomeAbout
            titleIntro={t('service:service2.title1')}
            title={t('service:service2.title2')}
            text={t('service:service2.text')}
            url="about-home.png"
          />
        </div>
      </div>
      <div className="service-product">
        <HomeProduct row1={row1} row2={row2} />
      </div>
    </div>
  );
}
