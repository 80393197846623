import React from 'react'
import NewsComp from '../components/news/NewsComp'
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Banner2 from '../components/common/banner/Banner2'

const News = (props) => {
  const {t} = useTranslation()

  return (
    <div>
       <Helmet>
    <title>{t('title.4')}</title>
</Helmet>
      <Banner2
      urlBanner="/NewsBG.jpg"
      titleBanner={t('banner.4')}
      
      />
      <NewsComp
        newsData = {props.newsData}
      />
    </div>
  )
}

export default News