import React from 'react'
import Banner from '../components/common/banner/Banner'
import Banner2 from '../components/common/banner/Banner2'
import ContactComp from '../components/contact/ContactComp'
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Contact = () => {

  const {t} = useTranslation()
  return (
    <div>
      <Helmet>
    <title>{t('title.5')}</title>
</Helmet>
      {/* <Banner
      photos={photos}
      /> */}
      <Banner2
      urlBanner="/contact-banner.jpg"
      titleBanner={t('banner.5')}
      />
      <ContactComp />
    </div>
  )
}

export default Contact