import "./HomeAbout.scss";
import "../../about/AboutComp.scss"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';

export default function HomeAbout(props) {

  const {t} = useTranslation()

  return (
    <div className="home-about-wrapper">
      <div className="home-about">
          <Fade bottom distance="5%">
            <div className="home-about-left">
              <h4 className="top-title">{props.titleIntro}</h4>
              <h2 className="main-title">{props.title}</h2>
              <p className="about-text">{props.text}</p>
              <NavLink to="/About"><button className="nav-btn">{t('btn.5')}</button></NavLink>
            </div>
          </Fade>
          <Fade bottom distance="7%">
            <div className="home-about-right">
              <img src={props.url} alt="about home" />
            </div>
          </Fade>
      </div>
    </div>
    
  );
}
