import React from "react";
import "./MissionVision.scss";
import { GiConvergenceTarget } from "react-icons/gi";
import { SiFuturelearn } from "react-icons/si";
import { useTranslation } from "react-i18next";
import { SlDiamond } from "react-icons/sl";
import Fade from 'react-reveal/Fade';
const MissionVision = () => {

  const {t} = useTranslation()

  return (
    <div>
      <div className="miss-vis-wrapper">
        <div className="miss-vis-container">
          <div className="mission-left-bg">
            <h4></h4>
          </div>
          <div className="mission-vision">
            <div className="miss-viss-inner">
              <Fade cascade left distance="5%">
                <div className="miss-vis-content">
                  <div className="mission">
                    <div className="mission-icon">
                      <GiConvergenceTarget color="#008be7" />
                    </div>
                    <h3>{t('about:mission.title')}</h3>
                    <p>
                    {t('about:mission.text')}
                    </p>
                  </div>
                  <div className="vision">
                    <div className="mission-icon">
                      <SiFuturelearn color="#008be7" />
                    </div>
                    <h3>{t('about:vision.title')}</h3>
                    <p>
                    {t('about:vision.text')}
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVision;
