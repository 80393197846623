import "./HomeProduct.scss";
import Card from "../../common/card/Card";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
export default function HomeProduct(props) {
  const { t } = useTranslation();
  // const row3 = [
  //   {
  //     title1: t('home:homeProduct.card7.title'),
  //     text2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //     url: "prod7.jpg",
  //     link: "/Services",
  //   }
  // ];

  return (
    <div className="home-product-wrapper">
      <div className="home-product">
        <div className="product-top-wrapper">
          <Fade bottom cascade distance="10%">
            <div className="product-top">
              <div className="title">{t("home:homeProduct.top.title")}</div>
              <div className="text">{t("home:homeProduct.top.text")}</div>
            </div>
          </Fade>
        </div>
        <Fade bottom cascade distance="3%">
          <div className="card-wrapper">
            <div className="row row-1">
              {props.row1.map((data) => {
                return (
                  <Card
                    title1={data.title1}
                    text1={data.text1}
                    text2={data.text2}
                    url={data.url}
                    link={data.link}
                  />
                );
              })}
            </div>

            <div className="row ">
              {props.row2.map((data) => {
                return (
                  <Card
                    title1={data.title1}
                    text1={data.text1}
                    text2={data.text2}
                    url={data.url}
                    link={data.link}
                  />
                );
              })}
            </div>
            {/* <div className="row row3">     
                  <Card
                    title1={row3[0].title1}
                    text2={row3[0].text2}
                    url={row3[0].url}
                    link={row3[0].link}
                  /> 
                    <Card  />
                    <Card  />
            </div> */}
          </div>
        </Fade>
      </div>
    </div>
  );
}
