import React from "react";
import AboutComp from "../components/about/AboutComp";
import Banner from "../components/common/banner/Banner";
import MissionVision from "../components/about/MissionVision";
import DevelopPlan from "../components/about/DevelopPlan";
import SocialImpact from "../components/about/SocialImpact";
import WhyUs from "../components/home/whyUs/WhyUs";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const About = () => {
 const {t} = useTranslation()
  const photos = [
    {
      id: "b1",
      url: "AboutBanner.jpg",
      headlineText: t("banner.2"),
    },
    {
      id: "b2",
      url: "AboutBanner2.jpg",
      headlineText: t("banner.2"),
    },
    {
      id: "b3",
      url: "AboutBanner3.jpg",
      headlineText: t("banner.2"),
    },
  ];


 

  return (
    <div>
      <Helmet>
    <title>{t('title.2')}</title>
</Helmet>
      <Banner
        photos={photos}
      />
      <AboutComp />
      <MissionVision />
      <DevelopPlan />
      <WhyUs />
      <SocialImpact />
    </div>
  );
};

export default About;
