import Banner from "../components/common/banner/Banner";
import ManagementTeam from "../components/management/ManagementTeam";
import "../components/news/NewsComp.scss";
import { useTranslation } from "react-i18next";
import Banner2 from "../components/common/banner/Banner2";

export default function Management() {
  const { t } = useTranslation();
  const images = ["news001-img1.jpg", "news001-img3.jpg", "news001-img2.jpg"];

  return (
    <div>
      {/* <Banner photos={photos} /> */}
      <Banner2 urlBanner="/TeamBanner2.jpg" titleBanner={t("banner.6")} />
      <ManagementTeam />
    </div>
  );
}
