import "./NewsComp.scss"
import NewsCard from "../home/homeNews/NewsCard"
import { useState } from "react";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";

export default function NewsComp() {

    const {t} = useTranslation()

  const pages = [
    [
      [
        {
            url: "news0018-img1.jpg",
            time: t('news:news18.date'),
            title: t('news:news18.title'),
            text: t('news:news18.text'),
            link: "/News/1"
        },
        {
            url: "news0019-img1.jpg",
            time: t('news:news19.date'),
            title: t('news:news19.title'),
            text: t('news:news19.text'),
            link: "/News/2"
        },
        {
            url: "news0020-img1.jpg",
            time: t('news:news20.date'),
            title: t('news:news20.title'),
            text: t('news:news20.text'),
            link: "/News/3"
        }
    ],
    [
        {
            url: "news0021-img1.jpg",
            time: t('news:news21.date'),
            title: t('news:news21.title'),
            text: "",
            link: "/News/4"
        },
        {
            url: "news0022-img1.jpg",
            time: t('news:news22.date'),
            title: t('news:news22.title'),
            text: t('news:news22.text'),
            link: "/News/5"
        },
        {
            url: "news001-img1.jpg",
            time: t('news:news1.date'),
            title: t('news:news1.title'),
            text: t('news:news1.text'),
            link: "/News/6"
        }
    ] 
      ],
        [
            [
                {
                    url: "news002-img1.jpg",
                    time: t('news:news2.date'),
                    title: t('news:news2.title'),
                    text: t('news:news2.text'),
                    link: "/News/7"
                },
                {
                    url: "news003-img1.jpg",
                    time: t('news:news3.date'),
                    title: t('news:news3.title'),
                    text: t('news:news3.text'),
                    link: "/News/8"
                },
                {
                    url: "news004-img1.jpg",
                    time: t('news:news4.date'),
                    title: t('news:news4.title'),
                    text: t('news:news4.text'),
                    link: "/News/9"
                }
            ],
            [
                {
                    url: "news005-img1.jpg",
                    time: t('news:news5.date'),
                    title: t('news:news5.title'),
                    text: "",
                    link: "/News/10"
                },
                {
                    url: "news006-img1.jpg",
                    time: t('news:news6.date'),
                    title: t('news:news6.title'),
                    text: t('news:news6.text'),
                    link: "/News/11"
                },
                {
                    url: "news007-img1.jpg",
                    time: t('news:news7.date'),
                    title: t('news:news7.title'),
                    text: t('news:news7.text'),
                    link: "/News/12"
                }
            ]   
        ],
        [
            [
                {
                    url: "news008-img1.jpg",
                    time: t('news:news8.date'),
                    title: t('news:news8.title'),
                    text: t('news:news8.text'),
                    link: "/News/13"
                },
                {
                    url: "news009-img1.jpg",
                    time: t('news:news9.date'),
                    title: t('news:news9.title'),
                    text: t('news:news9.text'),
                    link: "/News/14"
                },
                {
                    url: "news0010-img1.jpg",
                    time: t('news:news10.date'),
                    title: t('news:news10.title'),
                    text: t('news:news10.text'),
                    link: "/News/15"
                }
            ],
            [
                {
                    url: "news0011-img1.png",
                    time: t('news:news11.date'),
                    title: t('news:news11.title'),
                    text: t('news:news11.text'),
                    link: "/News/16"
                },
                {
                    url: "news0012-img1.png",
                    time: t('news:news12.date'),
                    title: t('news:news12.title'),
                    text: t('news:news12.text'),
                    link: "/News/17"
                },
                {
                    url: "news0013-img1.jpg",
                    time: t('news:news13.date'),
                    title: t('news:news13.title'),
                    text: t('news:news13.text'),
                    link: "/News/18"
                }
            ]
      ],
      [
        [
            {
                url: "news0014-img1.jpg",
                time: t('news:news14.date'),
                title: t('news:news14.title'),
                text: t('news:news14.text'),
                link: "/News/19"
            },
            {
                url: "news0015-img1.jpg",
                time: t('news:news15.date'),
                title: t('news:news15.title'),
                text: t('news:news15.text'),
                link: "/News/20"
            },
            {
                url: "news0016-img1.jpg",
                time: t('news:news16.date'),
                title: t('news:news16.title'),
                text: t('news:news16.text'),
                link: "/News/21"
            }
        ],
        [
            {
                url: "news0017-img1.jpg",
                time: t('news:news17.date'),
                title: t('news:news17.title'),
                text: t('news:news17.text'),
                link: "/News/22"
            }
            
            
        ]
    ]
    ]

    const [state, setState] = useState({
        current: 0
    });
    
  function next() {
    window.scrollTo(0, 0);
        setState((preState) => {
          if (state.current < (pages.length - 1)) {
            return {
              current: preState.current + 1
            };
          } else {
            return {
              current: pages.length - 1
            };
          }
        });
      }
    
  function prev() {
    window.scrollTo(0, 0);
        setState((preState) => {
          if (state.current > 0) {
            return {
              current: preState.current - 1
            };
          } else {
            return {
              current: 0
            };
          }
        });
      }

    return (
        <div className="news-comp-wrapper">
            <div className="news-comp">
  
                {pages.map((data1, idx) => {
                    return (
                        state.current === idx && (
                            <div className="page">
                                {data1.map((data2, idx) => {
                                    return (
                                        <div className="row">
                                            {data2.map((data3, idx) => {
                                                return (
                                            
                                                        <NewsCard
                                                        url={data3.url}
                                                        title={data3.title}
                                                        time={data3.time}
                                                        text={data3.text}
                                                        link={data3.link}
                                                    />
                                                
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    )
                })}

                <div className="page-number-wrapper">
            <div className="page-number-box" onClick={prev}>
              <BsArrowLeft />
            </div>
              {pages.map((data, idx) => {
                return (
                  <div className={(state.current === idx) ? "page-number-box page-number-box-active" : "page-number-box"}
                    onClick={() => {
                      setState((preState) => {
                        window.scrollTo(0, 0);
                        return {
                          current: idx
                        }
                    })
                  }}
                  ><span>{idx + 1}</span></div>
                  )
                })}
            <div className="page-number-box"  onClick={next}>
              <BsArrowRight />
            </div>
            </div>

            </div>
        </div>
    )
}