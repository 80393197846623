import React from "react";
import HomeBanner from "./../components/home/homeBanner/HomeBanner";
import HomeAbout from "../components/home/homeAbout/HomeAbout";
import HomeKeyFig from "../components/home/homeKeyFig/HomeKeyFig";
import WhyUs from "../components/home/whyUs/WhyUs";
import HomeProduct from "../components/home/homeProduct/HomeProduct";
import Clients from "../components/Client/Clients";
import HomePromo from "../components/home/homePromo/HomePromo";
import HomeNews from "../components/home/homeNews/HomeNews";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Home = (props) => {

  const { t } = useTranslation()
  
  const row1 = [
    {
      title1: t('home:homeProduct.card1.title'),
      text2: t('home:homeProduct.card1.text'),
      url: "Prod1.png",
      link: "/Services",
    },
    {
      title1: t('home:homeProduct.card2.title'),
      text2: t('home:homeProduct.card2.text'),
      url: "Tire.jpg",
      link: "/Services",
    },
    {
      title1: t('home:homeProduct.card3.title'),
      text2: t('home:homeProduct.card3.text'),
      url: "Prod4.png",
      link: "/Services",
    },
  ];
  const row2 = [
    {
      title1: t('home:homeProduct.card4.title'),
      text2: t('home:homeProduct.card4.text'),
      url: "power dis.png",
      link: "/Services",
    },
    {
      title1: t('home:homeProduct.card5.title'),
      text2: t('home:homeProduct.card5.text'),
      url: "Prod5.png",
      link: "/Services",
    },
    {
      title1: t('home:homeProduct.card6.title'),
      text2: t('home:homeProduct.card6.text'),
      url: "Paper2.jpg",
      link: "/Services",
    },
  ];
  

  return (
    <div>
      <Helmet>
    <title>{t('title.1')}</title>
</Helmet>
      <HomeBanner />
      <HomeAbout
        titleIntro={t('home:homeAbout.title1')}
        title={t('home:homeAbout.title2')}
        text={t('home:homeAbout.text')}
        url="HomeAbt2.png"
      />
      <HomeKeyFig />
      <WhyUs />
      <HomeProduct
        row1={row1}
        row2={row2}
      />
      <HomePromo />
      <HomeNews
      newsData={props.newsData}
      />
      <Clients />
    </div>
  );
};

export default Home;
