import "./NewsComp.scss"
import { useState } from "react";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

export default function PageNumber(props) {
    
    const navigate = useNavigate();
    
    function next() {
        if (parseInt(props.pid) < props.newsData.length) {
            navigate("/News/"+ (parseInt(props.pid)  + 1))
        }
      }
    
    function prev() {
        if (parseInt(props.pid) > 1) {
            navigate("/News/"+ (parseInt(props.pid)  - 1))
        }

      }

    return (
        <div className="page-number-wrapper">
        <div className="page-number-box" onClick={prev}>
          <BsArrowLeft />
        </div>
          {props.newsData.map((data, idx) => {
            return (
              <div className={(parseInt(props.pid) + 2 ) < idx || (parseInt(props.pid) - 2 > idx ) ? "page-number-box-hide": "page-number-box-show"}>
                  <div className={(parseInt(props.pid) - 1 === idx) ? "page-number-box page-number-box-active" : "page-number-box"}
                    onClick={() => {
                        navigate("/News/" + (idx+1))
                  
                  }}
                    >
                        <span>{idx + 1}</span>
                    </div>
              </div>
              )
            })}
        <div className="page-number-box"  onClick={next}>
          <BsArrowRight />
        </div>
        </div>
    )
}