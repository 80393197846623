import React from "react";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

const DevelopPlan = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="dev-plan-wrapper">
        <div className="dev-paln-container">
          <Fade cascade bottom distance="5%">
            <div className="dev-plans-headline">
              <div className="dev-intro">
                <h4>{t("about:strategy.title")}</h4>
              </div>
              <div className="dev-title">
                <h2>{t("about:strategy.text")}</h2>
              </div>
            </div>
          </Fade>
          <div className="dev-plan-content">
            <div className="dev-upper"></div>
            <Fade bottom cascade distance="5%">
              <div className="dev-lower">
                <div className="dev-plan-text">
                  <div className="plan-box-wrap">
                    <div className="plan-box">01</div>
                  </div>
                  <div className="plan-text">
                    <p>{t("about:strategy.text1")}</p>
                  </div>
                </div>
                <div className="dev-plan-text">
                  <div className="plan-box-wrap">
                    <div className="plan-box">02</div>
                  </div>
                  <div className="plan-text">
                    <p>{t("about:strategy.text2")}</p>
                  </div>
                </div>
                <div className="dev-plan-text">
                  <div className="plan-box-wrap">
                    <div className="plan-box">03</div>
                  </div>
                  <div className="plan-text">
                    <p>{t("about:strategy.text3")}</p>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopPlan;
