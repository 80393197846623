import "./ContactComp.scss";
import icon1 from "../../images/email-icon.png";
import icon2 from "../../images/phone-icon.png";
import icon3 from "../../images/location-icon2.png";
import img1 from "../../images/contact-img.jpg";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

export default function ContactComp() {

  const {t} = useTranslation()

  return (
    <div className="contact-comp-wrapper">
      <div className="contact-comp">
        <div className="contact-detail">
          <Fade bottom cascade distance="5%">
            <div className="top">
              <div className="title1">{t('contact:title1')}</div>
              <div className="title2">
                {t('contact:text1')}
              </div>
            </div>
          </Fade>
          <Fade bottom distance="10%" cascade delay={1100}>
            <div className="bottom">
              <div className="box">
                <img className="icon" src={icon3} alt="" />
                <div className="title">{t('contact:title2')}</div>
                <div className="text">
                  {t('contact:text2')}
                </div>
              </div>
              <div className="box">
                <img className="icon" src={icon1} alt="" />
                <div className="title">{t('contact:title3')}</div>
                <div className="text">info@wodaip.com</div>
                <div className="text">sales@wodaip.com</div>
              </div>
              <div className="box">
                <img className="icon" src={icon2} alt="" />
                <div className="title">{t('contact:title4')}</div>
                <div className="text">{t('contact:phone1')}<br />{t('contact:phone2')} <br />{t('contact:phone3')} < br/> {t('contact:phone4')}</div>
              </div>
            </div>
          </Fade>
        </div>
        <div className="contact-form">
          <div className="container-wrapper">
            <div className="container">
                <div className="left">
                  <img src={img1} alt="contact" />
                </div>
                <div className="right">
                  <div className="title">{t('contact:title5')}</div>
                  <div className="text">
                  </div>
                  <form className="form">
                    <label for="name" className="form-label">
                      {t('contact:form.title1')}
                    </label>
                    <input id="name" name="name" type="text" placeholder="" />
                    <label for="email" className="form-label">
                    {t('contact:form.title2')}
                    </label>
                    <input id="email" name="email" type="email" placeholder="" />
                    <label for="message" className="form-label">
                    {t('contact:form.title3')}
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      placeholder=""
                    ></textarea>
                    <div className="nav-btn">{t('btn.8')}</div>
                  </form>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-map" id="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d402.42322333752037!2d38.78436847141336!3d8.999677207201746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85d4e383bca3%3A0x161db87cf3c0f40f!2sWoda%20Metal%20Industry%20Plc!5e0!3m2!1sam!2set!4v1685540185335!5m2!1sam!2set"
          width="100%"
          height="100%"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
