import "./WhyUs.scss";
import icon1 from "../../../images/why-us-icon1.png";
import icon2 from "../../../images/why-us-icon2.png";
import icon3 from "../../../images/why-us-icon3.png";
import icon4 from "../../../images/why-us-icon4.png";
import icon5 from "../../../images/why-us-icon5.png";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

export default function WhyUs() {
  const { t } = useTranslation();

  return (
    <div className="why-us-wrapper">
      <div className="why-us">
        
          <div className="content">
            <Fade bottom distance="5%">
              <div className="top">
                <div className="title1">{t("home:whyUs.top.title")}</div>
                <div className="title2">{t("home:whyUs.top.text")}</div>
                <div className="text"></div>
              </div>
            </Fade>
            <div className="bottom">
              <Fade bottom distance="5%" cascade>
              <div className="row">
                
                  <div className="box">
                    <img className="icon" src={icon1} alt="why us" />
                    <div className="text-content">
                      <div className="box-title">{t("home:whyUs.1.title")}</div>
                      <div className="box-text">{t("home:whyUs.1.text")}</div>
                    </div>
                  </div>
               
                <div className="box">
                  <img className="icon" src={icon2} alt="why us" />
                  <div className="text-content">
                    <div className="box-title">{t("home:whyUs.2.title")}</div>
                    <div className="box-text">{t("home:whyUs.2.text")}</div>
                  </div>
                </div>
              </div>
               </Fade>
              <Fade bottom distance="7%" cascade>
                <div className="row">
                  <div className="box">
                    <img className="icon" src={icon3} alt="why us" />
                    <div className="text-content">
                      <div className="box-title">{t("home:whyUs.3.title")}</div>
                      <div className="box-text">{t("home:whyUs.3.text")}</div>
                    </div>
                  </div>
                  <div className="box">
                    <img className="icon" src={icon4} alt="why us" />
                    <div className="text-content">
                      <div className="box-title">{t("home:whyUs.4.title")}</div>
                      <div className="box-text">{t("home:whyUs.4.text")}</div>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade bottom distance="9%" cascade>
                <div className="row">
                  <div className="box">
                    <img className="icon" src={icon5} alt="why us" />
                    <div className="text-content">
                      <div className="box-title">{t("home:whyUs.5.title")}</div>
                      <div className="box-text">{t("home:whyUs.5.text")}</div>
                    </div>
                  </div>
                  <div className="box"></div>
                </div>
              </Fade>
            </div>
          </div>
      </div>
    </div>
  );
}
