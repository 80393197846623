import "./NewsDetail.scss";
import { NavLink } from "react-router-dom";
import SidebarContact from "../common/sidebarContact/SidebarContact";
import { useEffect, useState } from "react";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi2";
import PageNumber from "./PageNumber";
import SliderImage from "./SliderImage";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function NewsDetail(props) {
  const { t } = useTranslation()
  const location = useLocation();
  console.log(location.pathname)

  return (
    <div className="news-detail-wrapper">
      <div className="news-detail">
        <div className="news-detail-left">
          <div className="top">
            <div className="title">Latest News</div>
            <div className="row-wrapper">
              <div className="row">
                {props.newsData.map((data, idx) => {
                  if (idx < 3) {
                    return (
                      <NavLink to={"/News/" + (idx+1)}>
                        <div className={("/News/" + (idx+1) === location.pathname) ?"news-detail-card news-detail-card-active":"news-detail-card"}>
                          <div className="news-detail-imgs"><img src={data.newsImage[0]} alt="" /></div>
                          <div className="content">
                            <div className="headline">{data.title}</div>
                            <div className="date">{data.date}</div>
                          </div>
                        </div>
                      </NavLink>
                    );
                  }
                })}
              </div>
              <div className="row">
              {props.newsData.map((data, idx) => {
                  if (idx < 6 && idx > 2) {
                    return (
                      <NavLink to={"/News/" + (idx+1)}>
                        <div className={("/News/" + (idx+1) === location.pathname) ?"news-detail-card news-detail-card-active":"news-detail-card"}>
                          <div className="news-detail-imgs"><img src={data.newsImage[0]} alt="" /></div>
                          <div className="content">
                            <div className="headline">{data.title}</div>
                            <div className="date">{data.date}</div>
                          </div>
                        </div>
                      </NavLink>
                    );
                  }
                })}
              </div>
            </div>
          </div>

          <div className="sidebar-contact-wrapper">
            <SidebarContact />
          </div>
        </div>
        <div className="news-detail-right">
          <div className="headline">{props.headline}</div>
          <div className="date">{props.date}</div>

          <SliderImage
            newsImage={props.newsImage}
          />

          {props.newsText.map((data, idx) => {
            return <div className="text">{data}</div>;
          })}

          <PageNumber pid={props.pid}
          newsData={props.newsData}
          />
        </div>
      </div>
    </div>
  );
}
