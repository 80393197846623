import "./Card.scss"
import icon1 from "../../../images/production-icon.png"
import img1 from "../../../images/card.png"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function Card(props) {

    const {t} = useTranslation()

    return (
        <div className="card">
            <div className="top">
                {/* <img className="icon" src={icon1} alt="card icon" /> */}
                <div className="text-wrapper">
                    <div className="title">{ props.title1 }</div>
                    {/* <div className="text">{ props.text1 }</div> */}
                </div>
            </div>
            <div className="img-wrapper"><img className="img" src={props.url} alt="card img" /></div>
            <div className="bottom">
                <div className="text">{ props.text2 }</div>
                <NavLink to={props.link}>
                    <div className="nav-btn">{t('btn.5')}</div>
                </NavLink>
            </div>
        </div>
    )
}