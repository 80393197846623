import React from "react";
import "./Banner.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Dots from "./Dots";
const Banner = (props) => {
  const { t } = useTranslation();
 
  // const photos = [
  //   {
  //     id: "b1",
  //     url: "HomeBanner.png",
  //     headlineText: t("banner.1-2"),
  //   },
  //   {
  //     id: "b2",
  //     url: "banner-home-img2.jpg",
  //     headlineText: t("banner.1-4"),
  //   },
  //   {
  //     id: "b3",
  //     url: "banner-home-img3.jpg",
  //     headlineText: t("banner.1-6"),
  //   },
  // ];

  const delay = 4000;

  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === props.photos.length - 1 ? 0 : prevIndex + 1
        ),

      delay
    );

    return () => {
      resetTimeout();
    };
  }, [currentIndex, props.photos.length]);
  return (
    <div >
      {/* <div
        className="banner-common container-fluid  p-0 #banner-mgt"
        style={{
          backgroundImage: "url("+ props.urlBanner +")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="banner-overlay"></div>
          <div className=" banner-title  ">
              <div className="banner-header ">
                <h1 className="banner-title-span ">{props.titleBanner}</h1>
                <h4 className="banner-text-span ">{props.textBanner}</h4>
              </div>
          </div>
      </div> */}
      {props.photos.map((photo) => {
        return (
          <div
            className={
              props.photos[currentIndex].id === photo.id
                ? "banner-home--fade"
                : "banner-home--fade banner-home--slide "
            }
            key={photo.id}
          >
            <div className="banner-home-img-wrapper banner-common">
              <img className="banner-home--img" src={photo.url} alt="banner" />
              <div className="banner-home-img-overlay"></div>
            </div>
            <div className="banner-home-contents-wrapper">
              <div className="banner-home--contents">
                {/* <div className="banner-home-text--container">
                  <h1 className="banner-home--title">{photo.headlineText}</h1>
                  <div className="nav-btn-wrapper">
                    
                  </div>
                </div> */}
                <div className="banner-header ">
                <h1 className="banner-title-span ">{photo.headlineText}</h1>
                {/* <h4 className="banner-text-span ">{props.textBanner}</h4> */}
              </div>
              </div>
            </div>
            <Dots
        currentIndex={currentIndex}
        photos={props.photos}
        onclick={(currentIndex) => setCurrentIndex(currentIndex)}
      />
          </div>
        );
      })}
    </div>
  );
};

export default Banner;
