import NewsDetail from '../components/news/NewsDetail';
import Banner from '../components/common/banner/Banner2'
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';

export default function NewsDetails(props) {
    const { t } = useTranslation()
    const { pid } = useParams()
    
    console.log(pid)
    
const currentNews = props.newsData[pid-1]
    return (
        <div>
            <Helmet>
    <title>{t('title.4')}</title>
</Helmet>
            <Banner
        urlBanner="/NewsBG.jpg"
        titleBanner="News Detail "
        textBanner={currentNews.title}
      />
            <NewsDetail
                headline={currentNews.title}
                date={currentNews.date}
                newsText={currentNews.newsText}
                newsImage={currentNews.newsImage}
                pid={pid}
                newsData={props.newsData}
            />
        </div>
    )
}