import React from "react";
import "./AboutComp.scss";
import img2 from "../../images/AbtWodaImg.png";
import imgAbt from "../../images/about-home.png";
import logo1 from "../../images/woda-about-logo.png";
import logo2 from "../../images/crossbrace.png";
import logo3 from "../../images/Alpha-about-logo.png";
import logo4 from "../../images/wgoup-about-logo.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';

const AboutComp = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="about-wrapper">
        <div className="about-main-container">
          <div className="about-partners">
            <Fade cascade bottom distance="5%">
              <div className="partner-card">
                <div className="partner-card-1">
                  <div className="card-intro">
                    <h3 className="partner-card-title">
                      {t("about:about.1.title")}
                    </h3>
                    <p className="partner-card-text">
                      {t("about:about.1.text")} <br />
                    </p>
                    <p className="partner-link">
                      <NavLink target="_blank" to="https://wodametal.com/">
                        {t("btn.9")}
                      </NavLink>
                    </p>
                  </div>
                  <div className="partner-logo">
                    <div className="partner-logo-img woda-logo-abt">
                      <a href="https://wodametal.com/" target="_blank">
                        <img src={logo1} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="partner-card-1">
                  <div className="card-intro">
                    <h3 className="partner-card-title">
                      {t("about:about.2.title")}
                    </h3>
                    <p className="partner-card-text">{t("about:about.2.text")}</p>
                  </div>
                  <div className="partner-logo">
                    <div className="partner-logo-img">
                      <img src={logo3} alt="" />
                    </div>
                  </div>
                </div>
                <div className="partner-card-1">
                  <div className="card-intro">
                    <h3 className="partner-card-title">
                      {t("about:about.3.title")}
                    </h3>
                    <p className="partner-card-text">{t("about:about.3.text")}</p>
                  </div>
                  <div className="partner-logo">
                    <div className="partner-logo-img">
                      <img src={logo4} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <Fade cascade bottom distance="5%">
            <div className="about-woda-intro">
              <div className="about-woda-content">
                <h4 className="intro-title">{t("about:about.title1")}</h4>
                <h2 className="intro-main-title">{t("about:about.title2")}</h2>
                <p className="about-woda-text">
                  {t("about:about.text1")}
                  <br />
                  <br />
                  {t("about:about.text2")}{" "}
                </p>
              </div>
              <div className="about-woda-img">
                <img src={imgAbt} alt="Woda Industry PLC Billboard" />
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default AboutComp;
