import React from "react";
import "./AboutComp.scss";
import img1 from "../../images/pollution.png";
import img2 from "../../images/security.png";
import img3 from "../../images/electricity.png";
import img4 from "../../images/school.png";
import img5 from "../../images/water.png";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

const SocialImpact = () => {

  const {t} = useTranslation()

  return (
    <div className="social-impact-container">
      <div className="social-impact-wrapper">
        <Fade cascade bottom distance="5%">
          <div className="impact-title">
            <h4>{t('about:impact.title')}</h4>
            <h2 className="intro-main-title">
            {t('about:impact.title1')} <span>{t('about:impact.title2')}</span> - {t('about:impact.title3')}
            </h2>
            <p>
            {t('about:impact.text')}
            </p>
          </div>
        </Fade>
        <div className="social-impact">
          <div className="impact-wrapper">
            <Fade cascade bottom distance="5%">
              <div className="impact-top">
                <div className="impact">
                  <img src={img1} alt="" />
                  <h3>{t('about:impact.1.title')}</h3>
                  <p>
                  {t('about:impact.1.text')}
                  </p>
                </div>
                <div className="impact">
                  <img src={img2} alt="" />
                  <h3>{t('about:impact.2.title')}</h3>
                  <p>{t('about:impact.2.text')}</p>
                </div>
                <div className="impact">
                  <img src={img3} alt="" />
                  <h3>{t('about:impact.3.title')}</h3>
                  <p>{t('about:impact.3.text')}</p>
                </div>
              </div>
            </Fade>
            <Fade bottom cascade distance="5%">
              <div className="impact-bottom">
                <div className="impact">
                  <img src={img4} alt="" />
                  <h3>{t('about:impact.4.title')}</h3>
                  <p>
                  {t('about:impact.4.text')}
                  </p>
                </div>
                <div className="impact">
                  <img src={img5} alt="" />
                  <h3>{t('about:impact.5.title')}</h3>
                  <p>{t('about:impact.5.text')}</p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialImpact;
