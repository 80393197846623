import { useEffect } from "react";
import Navbar from "./components/common/navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import i18n from 'i18next';
import Home from "./routes/Home";
import About from "./routes/About";
import News from "./routes/News";
import Contact from "./routes/Contact";
import Footer from "./components/common/footer/Footer";
import Management from "./routes/Management";
import Social from "./routes/Social";
import Services  from "./routes/Services";
import NewsDetails from "./routes/NewsDetails";
import Cta from "./components/common/cta/Cta";
import { useTranslation } from "react-i18next";
function App() {

  const location = useLocation();
  const {t} = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  const newsData = [
    {
        title: t('news:news18.title'),
        date:"June 27, 2023",
        newsText: [
            t('news:detail18.text1'),
        ],
        newsImage: [
            "/news0018-img1.jpg",
            "/news0018-img2.jpg",
            "/news0018-img3.jpg",
            "/news0018-img4.jpg",
            "/news0018-img5.jpg",
            "/news0018-img6.jpg",
            "/news0018-img7.jpg",
        ]
    },
    {
        title: t('news:news19.title'),
        date:"June 11, 2023",
        newsText: [
            t('news:detail19.text1'),
            t('news:detail19.text2'),
            t('news:detail19.text3'),
            t('news:detail19.text4'),
            t('news:detail19.text5'),
        ],
        newsImage: [
            "/news0019-img1.jpg",
        ]
    },
    {
        title: t('news:news20.title'),
        date:"June 5, 2023",
        newsText: [
            t('news:detail20.text1'),
        ],
        newsImage: [
            "/news0020-img1.jpg",
            "/news0020-img2.jpg",
            "/news0020-img3.jpg",
            "/news0020-img4.jpg",
            "/news0020-img5.jpg",
        ]
    },
    {
        title: t('news:news21.title'),
        date:"June 4, 2023",
        newsText: [
            t('news:detail21.text1'),
            t('news:detail21.text2'),
        ],
        newsImage: [
            "/news0021-img1.jpg",
            "/news0021-img2.jpg",
        ]
    },
    {
        title: t('news:news22.title'),
        date:"May 30, 2023",
        newsText: [
            t('news:detail22.text1'),
            t('news:detail22.text2'),
        ],
        newsImage: [
            "/news0022-img1.jpg",
            "/news0022-img2.jpg",
            "/news0022-img3.jpg",
            "/news0022-img4.jpg",
            "/news0022-img5.jpg",
            "/news0022-img6.jpg",
        ]
    },
    {
        title: t('news:news1.title'),
        date:"May 21, 2023",
        newsText: [
            t('news:detail1.text1'),
            t('news:detail1.text2'),
            t('news:detail1.text3'),
            t('news:detail1.text4'),
            t('news:detail1.text5'),
            t('news:detail1.text6'),
            t('news:detail1.text7'),
            t('news:detail1.text8'),
            t('news:detail1.text9'),
            t('news:detail1.text10')
        ],
        newsImage: [
            "/news001-img1.jpg",
            "/news001-img2.jpg",
            "/news001-img3.jpg",
            "/news001-img4.jpg",
            "/news001-img5.jpg",
        ]
    },
    {
        title: t('news:news2.title'),
        date:"April 27, 2023",
        newsText: [
            t('news:detail2.text1')
        ],
        newsImage: [
            "/news002-img1.jpg",
            "/news002-img2.jpg",
            "/news002-img3.jpg",
            "/news002-img4.jpg",
            "/news002-img5.jpg",
            "/news002-img6.jpg",
          ]
    },
    {
        title: t('news:news3.title'),
        date:"April 27, 2023",
        newsText: [
            t('news:detail3.text1')
        ],
        newsImage: [
            "/news003-img1.jpg"
          ]
    },
    {
        title: t('news:news4.title'),
        date:"April 20, 2023",
        newsText: [
            ""
        ],
        newsImage: [
            "/news004-img1.jpg",
            "/news004-img2.jpg"
          ]
    },
    {
        title: t('news:news5.title'),
        date: "April 20, 2023",
        newsText: [
            t('news:detail5.text1'),
           t('news:detail5.text2'),
            t('news:detail5.text3'),
            t('news:detail5.text4')
        ],
        newsImage: [
            "/news005-img1.jpg",
            "/news005-img2.jpg"
          ]
    },
    {
        title: t('news:news6.title'),
        date:"April 17, 2023",
        newsText: [
            ""
        ],
        newsImage: [
            "/news006-img1.jpg",
            "/news006-img2.jpg",
            "/news006-img3.jpg",
            "/news006-img4.jpg",
            "/news006-img5.jpg",
            "/news006-img6.jpg",
            "/news006-img7.jpg",
          ]
    },
    {
        title: t('news:news7.title'),
        date:"January 23, 2023",
        newsText: [
            t('news:detail7.text1')
        ],
        newsImage: [
            "/news007-img1.jpg"
          ]
    },
    {
        title: t('news:news8.title'),
        date:"January 13, 2023",
        newsText: [
            t('news:detail8.text1')
        ],
        newsImage: [
            "/news008-img1.jpg"
          ]
    },
    {
        title: t('news:news9.title'),
        date:"October 28, 2022",
        newsText: [
            t('news:detail9.text1')
        ],
        newsImage: [
            "/news009-img1.jpg"
          ]
    },
    {
        title: t('news:news10.title'),
        date:"August 11, 2022",
        newsText: [
            t('news:detail10.text1')
        ],
        newsImage: [
            "/news0010-img1.jpg"
          ]
    },
    {
        title: t('news:news11.title'),
        date:"August 10, 2022",
        newsText: [
            t('news:detail11.text1')
        ],
        newsImage: [
            "/news0011-img1.png"
          ]
    },
    {
        title: t('news:news12.title'),
        date:"July 7, 2022",
        newsText: [
            t('news:detail12.text1'),
            t('news:detail12.text2'),
            t('news:detail12.text3')
        ],
        newsImage: [
            "/news0012-img1.png"
          ]
    },
    {
        title: t('news:news13.title'),
        date:"July 7, 2022",
        newsText: [
            t('news:detail13.text1')
        ],
        newsImage: [
            "/news0013-img1.jpg"
          ]
    },
    {
        title: t('news:news14.title'),
        date:"July 6, 2022",
        newsText: [
            t('news:detail14.text1')
        ],
        newsImage: [
            "/news0014-img1.jpg"
          ]
    },
    {
        title: t('news:news15.title'),
        date:"December 12, 2021",
        newsText: [
            t('news:detail15.text1')
        ],
        newsImage: [
            "/news0015-img1.jpg"
          ]
    },
    {
        title: t('news:news16.title'),
        date:"October 25, 2021",
        newsText: [
            t('news:detail16.text1')
        ],
        newsImage: [
            "/news0016-img1.jpg"
          ]
    },
    {
        title: t('news:news17.title'),
        date:"December 13, 2019",
        newsText: [
            t('news:detail17.text1')
        ],
        newsImage: [
            "/news0017-img1.jpg"
          ]
    }
]
  

  return (
    <div className="App" id={i18n.resolvedLanguage === "cn" ? "cn-lang" : "pt-lang"}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home newsData={newsData} />} />
        <Route path="/About" element={<About />} />
        <Route path="/Management" element={<Management />} />
        <Route path="/Social" element={<Social />} />
        <Route path="/Services" element={<Services />} /> 
        <Route path="/News" element={<News newsData={newsData} />} />
        <Route path="/News/:pid" element={<NewsDetails newsData={ newsData} />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Cta/>
      <Footer />
    </div>
  );
}

export default App;
