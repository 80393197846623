import "./HomeNews.scss";
import { NavLink } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';
export default function NewsCard(props) {

  const {t} = useTranslation()

  return (
    <Fade bottom distance="5%" cascade>
      <div className="news-card">
        <div className="img-wrapper">
          <img src={props.url} alt="news" />
        </div>
        <div className="content">
          <div className="time">{props.time}</div>
          <div className="title">{props.title}</div>
          <div className="text">{props.text}</div>
          <NavLink to={props.link}>
            <div className="nav-btn-news">
              <span>{ t('btn.4')}</span>
              <HiOutlineArrowRight />
            </div>
          </NavLink>
        </div>
      </div>
    </Fade>
  );
}
