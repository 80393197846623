import "./HomeNews.scss";
import NewsCard from "./NewsCard";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function HomeNews(props) {
  const { t } = useTranslation();

  const row1 = [
    {
      url: "news001-img1.jpg",
      time: "May 21, 2023",
      title: t("home:homeNews.news1.title"),
      text: t("home:homeNews.news1.text"),
      link: "/NewsDetail001",
    },
    {
      url: "news002-img1.jpg",
      time: "April 27, 2023",
      title: t("home:homeNews.news2.title"),
      text: t("home:homeNews.news2.text"),
      link: "/NewsDetail002",
    },
    {
      url: "news003-img1.jpg",
      time: "April 27, 2023",
      title: t("home:homeNews.news3.title"),
      text: t("home:homeNews.news3.text"),
      link: "/NewsDetail003",
    },
  ];

  return (
    <div className="home-news-wrapper">
      <div className="home-news">
        <Fade cascade bottom distance="5%">s
          <div className="top">
            <div className="title1">{t("home:homeNews.title1")}</div>
            <div className="title2">{t("home:homeNews.title2")}</div>
            <div className="text"></div>
          </div>{" "}
        </Fade>
        <div className="cards-wrapper">
          <div className="row">
            {props.newsData.map((data, idx) => {
              if (idx < 3) {
                return (
                  <NewsCard
                    url={data.newsImage[0]}
                    title={data.title}
                    time={data.date}
                    text={data.newsText[0]}
                    link={"/News/" + (idx + 1)}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
