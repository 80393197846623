import React from "react";
import "./Cta.scss";
import { BsArrowRightShort } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeadShake from 'react-reveal/HeadShake';

const Cta = () => {
  const {t} = useTranslation()
  return (
    <div>
      <div className="call-to-action">
        <div className="cta-wrapper">
          <div className="cta-intro">
            <h6>{t('home:cta.title')}</h6>
            {/* <h4>
             {t('home:cta.text')}
            </h4> */}
          </div>
          <HeadShake distance="5%"  >
            <div className="cta-btn">
              <NavLink to="/Contact">
                <button className="nav-btn cta-btn-new">
                  <div className="cta-btn-text">
                    <span>{t('btn.6')}</span>
                    <BsArrowRightShort color="white" size={20} />
                  </div>
                </button>
              </NavLink>
            </div>
          </HeadShake>
        </div>
      </div>
    </div>
  );
};

export default Cta;
