import React from "react";
import "./HomeBanner.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import icon1 from "../../../images/location-icon.png";
import icon2 from "../../../images/production-icon.png";
import icon3 from "../../../images/social-icon.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';

const HomeBanner = () => {
  const navigate = useNavigate();
  const goToTop = (path) => {
    navigate(path);
  };

  const { t } = useTranslation();

  const photos = [
    {
      id: "b1",
      url: "HomeBanner.png",
      headlineIntro: t("banner.1-1"),
      headlineText: t("banner.1-2"),
    },
    {
      id: "b2",
      url: "banner-home-img2.jpg",
      headlineIntro: t("banner.1-3"),
      headlineText: t("banner.1-4"),
    },
    {
      id: "b3",
      url: "banner-home-img3.jpg",
      headlineIntro: t("banner.1-5"),
      headlineText: t("banner.1-6"),
    },
  ];

  const delay = 4000;

  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === photos.length - 1 ? 0 : prevIndex + 1
        ),

      delay
    );

    return () => {
      resetTimeout();
    };
  }, [currentIndex, photos.length]);

  return (
    <div className="banner-home">
      <div className="banner-home--slider">
        {photos.map((photo) => {
          return (
            <div
              className={
                photos[currentIndex].id === photo.id
                  ? "banner-home--fade"
                  : "banner-home--fade banner-home--slide"
              }
              key={photo.id}
            >
              <div className="banner-home-img-wrapper">
                <img
                  className="banner-home--img"
                  src={photo.url}
                  alt="banner"
                />
                <div className="banner-home-img-overlay"></div>
              </div>
              <div className="banner-home-contents-wrapper">
                <div className="banner-home--contents">
                  <div className="banner-home-text--container">
                    <h4 className="banner-home--text">{photo.headlineIntro}</h4>
                    <h1 className="banner-home--title">{photo.headlineText}</h1>
                    <div className="nav-btn-wrapper">
                      <div
                        onClick={() => goToTop("/Services")}
                        className="nav-btn"
                      >
                        <span>{t("btn.2")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
{/* Banner Cards */}
      <div className="banner-home--cards">
        {" "}
        <Fade bottom distance="2%" >
          <div className="banner-home-cards--container">
            <div className="banner-home--card" onClick={() => goToTop("/Contact/#map")}>
                <img
                  className="banner-home-card--img"
                  src={icon1}
                  alt="productivity icon"
                />
                <div>
                  <h3 className="banner-home-card--title">
                    {t("home:homeCard.1.title")}
                  </h3>
                  <ul className="banner-home-card--text">
                  <li>{t("home:homeCard.1.text1")}</li>
                  <li>{t("home:homeCard.1.text2")}</li>
                  <li>{t("home:homeCard.1.text3")}</li>
                  </ul>
                </div>
            </div>
            <div className="banner-home--card">
              <img
                className="banner-home-card--img"
                src={icon2}
                alt="productivity icon"
              />
              <div>
                <h3 className="banner-home-card--title">
                  {t("home:homeCard.2.title")}
                </h3>
                <ul className="banner-home-card--text card--text-2">
                <li>{t("home:homeCard.2.text1")}</li>
                  <li>{t("home:homeCard.2.text2")}</li>
                  <li>{t("home:homeCard.2.text3")}</li>
                  <li>{t("home:homeCard.2.text4")}</li>
                </ul>
              </div>
            </div>
            <div className="banner-home--card banner-home-card-last" onClick={() => goToTop("/Management")}>
              <img
                className="banner-home-card--img"
                src={icon3}
                alt="productivity icon"
              />
              <div >
                <h3 className="banner-home-card--title">
                  {t("home:homeCard.3.title")}
                </h3>
                <ul className="banner-home-card--text">
                <li><span>{t("home:homeCard.3.text1")}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default HomeBanner;
