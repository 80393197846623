import React from "react";
import FooterLogo from "../../../images/WODA_Industrial_Logo_White.svg";
import "./Footer.scss";
import { GrFacebookOption } from "react-icons/gr";
import { FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiWechatFill } from "react-icons/ri";

const Footer = () => {

  const {t} = useTranslation()

  return (
    <div className="footer-container">
      <div className="footer-wrapper">
        <div className="intro-menu-wrapper">
          <div className="footer-intro">
            <div className="footer-logo">
              <div className="footer-logo-img">
                <img src={FooterLogo} alt="Footer_Logo" />
              </div>
              <div className="footer-intro-text footer-intro-text2 ">
                {t('footer.col1.text')}
              </div>
              <div className="footer-social-icons">
                <div className="footer-icon">
                  <GrFacebookOption color="white" size={16} />
                </div>
                <div className="footer-icon">
                  <FaTwitter color="white" size={16} />
                </div>
                <NavLink to="https://www.linkedin.com/company/woda-industrial-park/" target="_blank">
                  <div className="footer-icon">
                    <FaLinkedinIn color="white" size={16} />
                  </div>
                </NavLink>
                <div className="footer-icon">
                  <RiWechatFill color="white" size={16} />
                </div>
              </div>
            </div>
          </div>
          <div className="menu-wrapper">
            <div className="menu">
              <div className="menu-title">
                <h5>{t('footer.col2.title')}</h5>
              </div>
              <div>
                <ul className="menu-lists">
                  <NavLink to="/">
                    <li className="menu-item">{t('footer.col2.list1')}</li>
                  </NavLink>
                  <NavLink to="/About">
                    <li className="menu-item">{t('footer.col2.list2')}</li>
                  </NavLink>
                  <NavLink to="/Services">
                    <li className="menu-item">{t('footer.col2.list3')}</li>
                  </NavLink>
                  <NavLink to="/News">
                    <li className="menu-item">{t('footer.col2.list4')}</li>
                  </NavLink>
                  <NavLink to="/Contact">
                    <li className="menu-item">{t('footer.col2.list5')}</li>
                  </NavLink>
                </ul>
              </div>
            </div>
            <div className="prod-menu">
              <div className="menu-title">
                <h5>{t('footer.col3.title')}</h5>
              </div>
              <ul className="menu-lists">
                <NavLink to="/Services">
                  <li className="menu-item">{t('footer.col3.list1')}</li>
                </NavLink>
                <NavLink to="/Services">
                  <li className="menu-item">{t('footer.col3.list2')}</li>
                </NavLink>
                <NavLink to="/Services">
                  <li className="menu-item">{t('footer.col3.list3')}</li>
                </NavLink>
                <NavLink to="/Services">
                  <li className="menu-item">{t('footer.col3.list4')}</li>
                </NavLink>
                <NavLink to="/Services">
                  <li className="menu-item">{t('footer.col3.list7')}</li>
                </NavLink>
                <NavLink to="/Services">
                  <li className="menu-item">{t('footer.col3.list5')}</li>
                </NavLink>
                <NavLink to="/Services">
                  <li className="menu-item">{t('footer.col3.list6')}</li>
                </NavLink>
              </ul>
            </div>
          </div>
        </div>
        <div className="contact-menu">
          <div className="newsletter-wrapper">
            <div className="footer-contact menu-title">
              <h5>{t('footer.col4.title')}</h5>
            </div>
            <div className="footer-intro-text">
            {t('footer.col4.text')}
            </div>
          </div>
          <form action="">
            <div className="form-input-wrapper">
              <input type="text" name="Name" placeholder={t('footer.col4.form.1')} />
              <input type="email" name="Name" placeholder={t('footer.col4.form.2')} />
            </div>
            <div className="sign-up-btn">
              <div>{t('btn.7')} </div>
            </div>
          </form>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="copy-right">
         {t('footer.copyright.1')}
        </div>
        <div className="terms">
          <ul className="terms-list">
            <li>{t('footer.copyright.2')}</li>
            <li>{t('footer.copyright.3')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
