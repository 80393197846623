import "./HomeKeyFig.scss";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';
export default function HomeKeyFig() {

  const {t} =  useTranslation()

  return (
    <div className="home-key-fig-wrapper">
      <div className="home-key-fig">
        
          <div className="home-key-fig-content">
            {/* <div className="top-number">100,000+</div> */}
            <h4 className="top-title-intro"></h4>
            <Fade bottom cascade distance="10%">
              <div className="top-title">
              {t('home:keyFig.top.title')}
              </div>
              <div className="key-fig-text">
              {t('home:keyFig.top.text')}
              </div>
            </Fade>
    
              <Fade bottom cascade distance="10%">
                <div className="key-fig-bottom-wrapper">
                  <div className="key-fig-row">
                    <div className="key-fig-bottom">
                      <div className="top-number">100<span className="unit">Mw</span></div>
                      <div className="bottom-text"> {t('home:keyFig.bottom.text1')}</div>
                    </div>
                    <div className="key-fig-bottom">
                      <div className="top-number">100<span className="unit">Ha</span> </div>
                      <div className="bottom-text">{t('home:keyFig.bottom.text2')}</div>
                    </div>
                  </div>
                  <div className="key-fig-bottom">
                    <div className="top-number">100<span className="unit">M</span> </div>
                    <div className="bottom-text">{t('home:keyFig.bottom.text3')}</div>
                  </div>
                </div>
              </Fade>
          </div>
      </div>
    </div>
  );
}
