import "./Navbar.scss";
import logo from "../../../images/WODA_Industrial_Logo_Red.svg";
import {
  MdLocationOn,
  MdOutlineMarkEmailRead,
  MdMenu,
  MdClose,
} from "react-icons/md";
import {BiChevronDown, BiChevronUp} from "react-icons/bi"
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  function clickLanguage(lang) {
    i18n.changeLanguage(lang);
  }

  const goToTop = (path) => {
    navigate(path);
    if (window.innerWidth < 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          openMenu: false
        };
      });
    }
  };
  const [state, setState] = useState({
    openLang: false,
    openAbout: false,
    openProduct: false,
    openMenu: false,
    hideTopNav: false,
  });

  useEffect(() => {
    if (window.scrollY > 41) {
      setState((prevState) => {
        return {
          ...prevState,
          hideTopNav: true,
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          hideTopNav: false,
        };
      });
    }
  });

  function showLang() {
    if (window.innerWidth > 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          openLang: true,
        };
      });
    }
  }

  function hideLang() {
    if (window.innerWidth > 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          openLang: false,
        };
      });
    }
  }

  function showAbout() {
    if (window.innerWidth > 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          openAbout: true,
        };
      });
    }
  }

  function hideAbout() {
    if (window.innerWidth > 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          openAbout: false,
        };
      });
    }
  }

  function showProduct() {
    if (window.innerWidth > 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          openProduct: true,
        };
      });
    }
  }

  function hideProduct() {
    if (window.innerWidth > 1024) {
      setState((prevState) => {
        return {
          ...prevState,
          openProduct: false,
        };
      });
    }
  }

  const location = useLocation();

  function currentPage() {
    if (
      location.pathname === "/About" ||
      location.pathname === "/Management" ||
      location.pathname === "/Social"
    ) {
      return "about";
    }

    // if (
    //   location.pathname === "/Towers" ||
    //   location.pathname === "/SteelStructure" ||
    //   location.pathname === "/Cables" ||
    //   location.pathname === "/Galvanization"
    // ) {
    //   return "product";
    // }
  }

  function toggleMenu() {
    if (window.innerWidth < 1025) {
      setState((prevState) => {
        return {
          ...prevState,
          openMenu: !prevState.openMenu,
        };
      });
    }
  }

  function toggleLang() {
    console.log(window.scrollY);
    if (window.innerWidth < 1025) {
      setState((prevState) => {
        return {
          ...prevState,
          openLang: !prevState.openLang,
        };
      });
    }
  }

  function toggleAbout() {
    if (window.innerWidth < 1025) {
      setState((prevState) => {
        return {
          ...prevState,
          openAbout: !prevState.openAbout,
        };
      });
    }
  }

  function toggleProduct() {
    if (window.innerWidth < 1025) {
      setState((prevState) => {
        return {
          ...prevState,
          openProduct: !prevState.openProduct,
        };
      });
    }
  }

  return (
    <div
      className={
        state.hideTopNav ? "navbar-wrapper fixed-navbar" : "navbar-wrapper"
      }
    >
      <div className="navbar-top-wrapper">
        <div className="navbar-top">
          <div className="nav-location">
            <span className="nav-icon">
              <MdLocationOn />
            </span>
            <span className="nav-text">
              {t('address')}
            </span>
          </div>
          <div className="nav-right">
            <div className="nav-email">
              <span className="nav-icon">
                <MdOutlineMarkEmailRead />
              </span>
              <span className="nav-text">info@wodaip.com</span>
            </div>
            <div className="nav-phone">
              <span className="nav-icon">
                <TbDeviceLandlinePhone />
              </span>
              <span className="nav-text">+251 118-229-575</span>
            </div>
            <div
              className="nav-lang-wrapper"
              onMouseEnter={showLang}
              onMouseLeave={hideLang}
              onClick={toggleLang}
            >
              <div className="nav-lang">
                <span className="nav-text">{t('btn.3')}</span>
                <span className="nav-icon">
                  <BiChevronDown />
                </span>
              </div>
              <div className="nav-lang-dropdown-wrapper">
                {state.openLang && (
                  <div>
                    <div className="nav-lang-dropdown">
                      <span className="nav-text" onClick={() => clickLanguage("en")}
                style={{
                  color: i18n.resolvedLanguage === "en" ? "#ff9901" : "",
                }}>EN</span>
                    </div>
                    <div className="nav-lang-dropdown">
                      <span className="nav-text" onClick={() => clickLanguage("cn")}
                style={{
                  color: i18n.resolvedLanguage === "cn" ? "#ff9901" : "",
                }}>中文</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-bottom-wrapper">
        <div className="navbar-bottom">
          <div className="logo-wrapper">
            <img
              onClick={() => goToTop("/")}
              className="logo"
              src={logo}
              alt="logo"
            />
          </div>
          <div className="navlinks">
            <ul
              className={
                state.openMenu ? "navlink-wrapper menu-open" : "navlink-wrapper"
              }
            >
              <NavLink to="/" onClick={toggleMenu}>
                {" "}
                <li>{t('links.1')}</li>
              </NavLink>
             <div> <li
                className="nav-dropdown-wrapper"
                onMouseEnter={showAbout}
                onMouseLeave={hideAbout}
                onClick={toggleAbout}
              >
                <div
                  className="nav-link"
                  style={{
                    color:
                      currentPage() === "about" ? "rgba(34, 35, 52, 0.5)" : ""
                  }}
                >
                  <span>{t('links.2')}</span> {state.openAbout ? <span><BiChevronUp/></span>: <span><BiChevronDown/></span>}
                  {/* <span>
                    <BiChevronDown />
                  </span> */}
                </div>
                {state.openAbout && (
                  <ul className="nav-dropdown">
                    <NavLink to="/About" onClick={toggleMenu}>
                      <li>
                        <span>Woda Industrial Park</span>
                      </li>
                    </NavLink>
                    <NavLink to="/Management" onClick={toggleMenu}>
                      <li>
                        <span>Management Team</span>
                      </li>
                    </NavLink>
                  </ul>
                )}
              </li></div>
              <NavLink to="/Services" onClick={toggleMenu}><li
                className="nav-dropdown-wrapper"
                onMouseOver={showProduct}
                onMouseOut={hideProduct}
                onClick={toggleProduct}
              >
                <div
                  className="nav-link"
                  style={{
                    color:
                      currentPage() === "product"
                        ? "rgba(34, 35, 52, 0.5)"
                        : "",
                  }}
                >
                  <span>{t('links.3')}</span>
                  {/* <span>
                    <BiChevronDown />
                  </span> */}
                </div>
                {/* {state.openProduct && (
                  <ul className="nav-dropdown">
                    <NavLink to="/Towers">
                      <li>
                        <span>Towers</span>
                      </li>
                    </NavLink>
                    <NavLink to="SteelStructure">
                      <li>
                        <span>Steel Structures</span>
                      </li>
                    </NavLink>
                    <NavLink to="/Cables">
                      <li>
                        <span>Cables</span>
                      </li>
                    </NavLink>
                    <NavLink to="/Galvanization">
                      <li>
                        <span>Galvanization</span>
                      </li>
                    </NavLink>

                    <NavLink to="/PlannedProduct">
                      <li>
                        <span>Planned Product</span>
                      </li>
                    </NavLink>
                  </ul>
                )} */}
              </li></NavLink>
              <NavLink to="/News" onClick={toggleMenu}>
                <li>{t('links.4')}</li>
              </NavLink>
              <NavLink to="/Contact" onClick={toggleMenu}>
                {" "}
                <li>{t('links.5')}</li>
              </NavLink>
            </ul>
            <div className="nav-btn-wrapper">
              <NavLink to="/Contact">
                <div className="nav-btn">
                  <span>{t('btn.1')}</span>
                </div>
              </NavLink>
            </div>
          </div>
          <div className="nav-mobile-menu-wrapper">
            <div className="nav-mobile-menu" onClick={toggleMenu}>
              {state.openMenu ? <MdClose /> : <MdMenu />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
