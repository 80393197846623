import React from "react";
import "./ManagementTeam.scss";
import team1 from "../../images/team1.jpg";
import team2 from "../../images/teamR.jpg";
import team3 from "../../images/teamR2.jpg";
import team4 from "../../images/teamR3.jpg";
import team5 from "../../images/teamL4.jpg";
import team6 from "../../images/teamL3.jpg";
import team7 from "../../images/teamL2.jpg";
import team8 from "../../images/teamL.jpg";
import teamM from "../../images/TeamM1.jpg";
import teamM2 from "../../images/TeamM2.jpg";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

const ManagementTeam = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mang-team-container">
        <div className="mang-team-wrapper">
          <Fade bottom cascade distance="5%">
            <div className="team-content">
              <h4>{t("about:mngt.title-intro")}</h4>
              <h2 className="intro-main-title">{t("about:mngt.title")}</h2>
              <p>{t("about:mngt.text")}</p>
            </div>
          </Fade>
          <div className="mang-team-card">
            <div className="cards-row-1">
              <div className="cards-row-1-1">
                <Fade distance="5%" bottom cascade>
                  <div className="team-card">
                    <div className="team-img-wrapper">
                      <img src={team4} alt="" />
                    </div>
                    <div className="team-intro">
                      <h3>{t("about:mngt.staff-name1")}</h3>
                      {/* <h5>{t("about:mngt.staff-position1")}</h5> */}
                    </div>
                  </div>
                </Fade>
                <Fade distance="5%" bottom cascade>
                  <div className="team-card">
                    <div className="team-img-wrapper">
                      <img src={team3} alt="" />
                    </div>
                    <div className="team-intro">
                      <h3>{t("about:mngt.staff-name2")}</h3>
                      {/* <h5>{t("about:mngt.staff-position2")}</h5> */}
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="cards-row-1-1">
                <Fade distance="5%" bottom cascade>
                  <div className="team-card">
                    <div className="team-img-wrapper">
                      <img src={team2} alt="" />
                    </div>
                    <div className="team-intro">
                      <h3>{t("about:mngt.staff-name3")}</h3>
                      {/* <h5>{t("about:mngt.staff-position3")}</h5> */}
                    </div>
                  </div>
                </Fade>
                <Fade distance="5%" bottom cascade>
                  <div className="team-card">
                    <div className="team-img-wrapper">
                      <img src={team1} alt="" />
                    </div>
                    <div className="team-intro">
                      <h3>{t("about:mngt.staff-name4")}</h3>
                      {/* <h5>{t("about:mngt.staff-position4")}</h5> */}
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="row-mid">
              <Fade distance="7%" bottom cascade>
                <div className="row-mid-1">
                  <div className="team-img-wrapper">
                    <img src={teamM2} alt="" />
                  </div>
                </div>
              </Fade>
              <Fade distance="7%" bottom cascade>
                <div className="row-mid-2">
                  <div className="team-img-wrapper">
                    <img src={teamM} alt="" />
                  </div>
                </div>
              </Fade>
            </div>
            <div className="cards-row-2">
                <div className="cards-row-2-2">
                  <Fade distance="10" bottom cascade delay={1100}>
                    <div className="team-card">
                      <div className="team-img-wrapper">
                        <img src={team5} alt="" />
                      </div>
                      <div className="team-intro">
                        <h3>{t("about:mngt.staff-name5")}</h3>
                        {/* <h5>{t("about:mngt.staff-position5")}</h5> */}
                      </div>
                    </div>
                  </Fade>
                  <Fade distance="10" bottom cascade delay={1100}>
                    <div className="team-card">
                      <div className="team-img-wrapper">
                        <img src={team6} alt="" />
                      </div>
                      <div className="team-intro">
                        <h3>{t("about:mngt.staff-name6")}</h3>
                        {/* <h5>{t("about:mngt.staff-position6")}</h5> */}
                      </div>
                    </div>
                  </Fade>
                </div>
              <Fade distance="10%" bottom cascade delay={1300} >
                <div className="cards-row-2-2">
                  <div className="team-card">
                    <div className="team-img-wrapper">
                      <img src={team7} alt="" />
                    </div>
                    <div className="team-intro">
                      <h3>{t("about:mngt.staff-name7")}</h3>
                      {/* <h5>{t("about:mngt.staff-position7")}</h5> */}
                    </div>
                  </div>
                  <div className="team-card">
                    <div className="team-img-wrapper">
                      <img src={team8} alt="" />
                    </div>
                    <div className="team-intro">
                      <h3>{t("about:mngt.staff-name8")}</h3>
                      {/* <h5>{t("about:mngt.staff-position8")}</h5> */}
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementTeam;
