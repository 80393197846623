import "./HomePromo.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function HomePromo() {

  const {t} = useTranslation()

  return (
    <div className="home-promo-wrapper">
      <div className="home-promo">
          <Fade left cascade distance="10%">
            <div className="content">
              <h2 className="title">
                {t('home:homePromo.title')}
              </h2>
              <p className="text">
                {t('home:homePromo.text')}
              </p>
              <NavLink to="/Contact">
                <button className="nav-btn">{t('btn.1')}</button>
              </NavLink>
            </div>
          </Fade>
      </div>
      <div className="overlay"></div>
    </div>
  );
}
