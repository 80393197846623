import React from "react";
import "./Banner.scss";
import { useTranslation } from "react-i18next";
const Banner = (props) => {
  const { t } = useTranslation();
  return (
    <div >
      <div
        className="banner-common container-fluid  p-0 #banner-mgt"
        style={{
          backgroundImage: "url("+ props.urlBanner +")",
          backgroundPosition:"center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="banner-overlay"></div>
          <div className=" banner-title  ">
              <div className="banner-header ">
                <h1 className="banner-title-span ">{props.titleBanner}</h1>
                <h4 className="banner-text-span ">{props.textBanner}</h4>
              </div>
          </div>
      </div>
     
    </div>
  );
};

export default Banner;
