import React from "react";
import teleGray from "../../images/ethio-telecom-gray.png";
import teleColor from "../../images/ethio-telecom-color.png";
import safariColor from "../../images/safaricom-logo-color.png";
import safariGray from "../../images/safaricom-logo-gray.png";
import huaweiColor from "../../images/Huawei-logo-color.png";
import huaweiGray from "../../images/Huawei-logo-gray.png";
import zteColor from "../../images/ZTE-logo.svg.png";
import zteGray from "../../images/ZTE-logo-Gray.svg.png";
import zhaoweiColor from "../../images/zhaowei-logo-color.jpg";
import zhaoweiGray from "../../images/zhaowei-logo-color-gray.png";
import Fade from "react-reveal/Fade";

import "./Clients.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const normalStyle = {
  transition: "all 3s ease-out",
};
const Clients = () => {
  const [over, setOver] = useState(false);
  const [over2, setOver2] = useState(false);
  const [over3, setOver3] = useState(false);
  const [over4, setOver4] = useState(false);
  const [over5, setOver5] = useState(false);

  const { t } = useTranslation()
  
  return (
    <div className="client-logo-container">
        <Fade bottom cascade distance="5%">
          <div className="client-content">
            <div className="client-headline">{t('home:clients.title')}</div>
            <div className="client-text">
              <p>
                {t('home:clients.text')}
              </p>
            </div>
          </div>
        </Fade>
        <div className="client-logos">
          <div
            className=" client-logo-wrapper tele"
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
          >
            <img
              className="client-logo-img"
              style={normalStyle}
              src={over ? teleColor : teleGray}
              alt="ethio telecom logo"
            />
          </div>
          <div
            className="client-logo-wrapper  safari"
            onMouseOver={() => setOver2(true)}
            onMouseOut={() => setOver2(false)}
          >
            <img
              className="client-logo-img"
              src={over2 ? safariColor : safariGray}
              alt="safaricom logo"
            />
          </div>
          <div
            className="client-logo-wrapper huawei"
            onMouseOver={() => setOver3(true)}
            onMouseOut={() => setOver3(false)}
          >
            <img
              className="client-logo-img"
              src={over3 ? huaweiColor : huaweiGray}
              alt="ethio telecom logo"
            />
          </div>
          <div
            className="client-logo-wrapper  zhaowei"
            onMouseOver={() => setOver4(true)}
            onMouseOut={() => setOver4(false)}
          >
            <img
              className="client-logo-img"
              src={over4 ? zhaoweiColor : zhaoweiGray}
              alt="ethio telecom logo"
            />
          </div>
          <div
            className="client-logo-wrapper  zte"
            onMouseOver={() => setOver5(true)}
            onMouseOut={() => setOver5(false)}
          >
            <img
              className="client-logo-img "
              src={over5 ? zteColor : zteGray}
              alt="ethio telecom logo"
            />
          </div>
        </div>
    </div>
  );
};

export default Clients;
