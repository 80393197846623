import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import commonCN from "./locales/cn/common.json"
import homeCN from "./locales/cn/home.json"
import aboutCN from "./locales/cn/about.json"
import serviceCN from "./locales/cn/service.json"
import contactCN from "./locales/cn/contact.json"
import newsCN from "./locales/cn/news.json"

import common from "./locales/en/common.json"
import home from "./locales/en/home.json"
import about from "./locales/en/about.json"
import service from "./locales/en/service.json"
import contact from "./locales/en/contact.json"
import news from "./locales/en/news.json"

const languages = ['en', 'cn'];

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    partialBundledLanguages: true,
    ns: ['common'],
    resources: {
      'en': {
        common,
        home,
        about,
        service,
        contact,
        news
     },
      'cn': {
        common: commonCN,
        home: homeCN,
        about: aboutCN,
        service: serviceCN,
        contact: contactCN,
        news: newsCN
      },
    }
   
  });

 

export default i18n;